<template>
  <div class="wrap">
    <Loading :loading="loading" />
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile/user_info">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.withdrawal") }}</li>
      </ul>
    </div>
    <div class="main main_page">
      <div class="Sys_list_div pl-20"  v-if="dataList">
        
        
        <div class="box_div" v-for="(item, index) in dataList" :key="index" @click="handleDetail(item.myBankId)">
          <router-link to="">
            <span class="name">{{ t("profile.label.methodType_"+item.methodType) }}</span>
            <span class="iconfont icon-you2"></span>
          </router-link>
        </div>
        
      </div>
      <div class="Sys_submit_btn Sys_submit_btn_b">
        <button @click="handleLogout" type="button">
          {{ t("common.button.addWithdrawal") }}
        </button>
      </div>
    </div>
    <AModal
      centered
      :mask="true"
      :maskClosable="false"
      :okText="t('common.button.ok')"
      :cancelText="t('common.button.cancel')"
      v-model:visible="avatarModal"
      @ok="changeAvatar"
      :zIndex="9999"
      width="85%"
      wrapClassName="Sys_Popup"
      :title="t('home.ChooseYourAvatar')"
    >
      <div class="Sys_Popup_wrap">
        <a-radio-group v-model:value="chooseAvatar" name="radioGroup">
          <a-radio v-for="(item, index) in 33" :key="index" :value="'' + index">
            <StaticImage
              :style="{
                width: '30px',
                height: '30px',
                borderRadius: '30px',
                marginTop: '5px',
                marginBottom: '5px',
              }"
              :url="'Head/' + index + '.png'"
            ></StaticImage>
          </a-radio>
        </a-radio-group>
      </div>
    </AModal>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from "vue";
import { formatMobile } from "@/utils/formatter";
import StaticImage from "@/components/StaticImage.vue";
import {useI18n} from "@/lang";
import { useRouter } from "vue-router";
import api from "@/api";
// import { message } from "ant-design-vue";
import { userDetail } from "@/hooks/userDetail";
import Loading from "@/components/Loading.vue";
export default defineComponent({
  components: { StaticImage,Loading },
  setup() {
    const langSelect= ref(null)
    const { t,locale } = useI18n();
    const locale_ = ref(locale.value||'en')
    watch(locale_,(newVal)=>{
      localStorage.setItem('locale',newVal)
      locale.value = newVal
    })
    
    const router = useRouter();
    let avatarModal = ref(false);
    let chooseAvatar = ref("0");
    const loading = ref(false);

    let dataList = ref(null);
    onMounted(async () => {
      let res = await api.getUserWithdrawal();
      if (res?.data?.success) {
        dataList.value = res.data?.data || [];
      }
      // console.log(dataList.value,'-----')
    });

    function handleDetail(myBankId){
      sessionStorage.setItem('userMyBankId', myBankId)
      console.log(sessionStorage.getItem('userMyBankId'),'-sdfsdfsdf',myBankId)
      router.replace("/profile/bankcard");
    }
    function handleLogout() {
      // loading.value = true
      sessionStorage.setItem('userMyBankId', '')
      router.replace("/profile/bankcard");
    }
    async function changeAvatar() {
      let res = await api.updateAvatar(chooseAvatar.value);
      if (res && res.data && res.data.success) {
        avatarModal.value = false;
        api.getUserDetails();
      }
    }

    function showAvatarModal() {
      avatarModal.value = true;
      chooseAvatar.value = userDetail.value.avatarAddress;
    }
    return {
      t,
      formatMobile,
      handleLogout,
      userDetail,
      changeAvatar,
      showAvatarModal,
      chooseAvatar,
      avatarModal,
      loading,
      locale_,
      langSelect,
      dataList,
      handleDetail
    };
  },
});
</script>
<style lang="scss" scoped>
.langSelect{
  border:none;
  background: none;
}
.Sys_list_div .box_div span.name
{
  font-size: 16px;
}
</style>